import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Title } from "../../components/Title";
import { database } from "../../config/firebase";
import { Fab, IconButton, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Delete";
import { Link, useHistory } from "react-router-dom";

// import { Container } from './styles';

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    height: "70vh",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
    width: "100%",
    height: "70vh",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const Stores: React.FC = () => {
  const history = useHistory();
  const [stores, setStores] = useState<any[]>([]);
  const classes = useStyles();

  function loadData() {
    database
      .collection("stores")
      .get()
      .then((r) => setStores(r.docs.map((d) => ({ id: d.id, ...d.data() }))));
  }

  useEffect(() => {
    loadData();
  }, []);

  async function removeItem(id: string) {
    if (window.confirm("Deseja realmente remover esse item?")) {
      await database.collection("stores").doc(id).delete();
      loadData();
    }
  }

  return (
    <React.Fragment>
      <Title>Lojas</Title>

      <div className={classes.root}>
        {/* <Paper className={classes.paper}> */}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer component={Paper}>
          <Table id="challenges" size="medium">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10%" }}>#</TableCell>
                <TableCell style={{ width: "15%" }}>Capa</TableCell>
                <TableCell style={{ width: "25%" }}>Titulo</TableCell>
                <TableCell style={{ width: "40%" }}>Descrição</TableCell>
                <TableCell width="10%">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.map((row) => (
                <TableRow key={row.id} style={{ height: "50px" }}>
                  <TableCell
                    onClick={() => history.push(`/stores/new/${row.id}`)}
                  >
                    <Link to={`/users/new/${row.id}`}>{row.id}</Link>
                  </TableCell>
                  <TableCell>
                    <div style={{}}>
                      {row.image && (
                        <img
                          src={row.image}
                          style={{ width: "100%" }}
                          alt="capa"
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    <IconButton
                      color="inherit"
                      onClick={() => removeItem(row?.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Paper> */}

        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={() => history.push(`/stores/new`)}
        >
          <AddIcon />
        </Fab>
      </div>
    </React.Fragment>
  );
};

export default Stores;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";

import { Title } from "../../components/Title";
import { database } from "../../config/firebase";
import { Button, Grid, Paper, TextField } from "@material-ui/core";

// import { Container } from './styles';

import "./styles.css";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "2rem",
  },
}));

type FormData = {
  id: string;
  title: string;
  description: string;
  challenge_of_week: string;
  exercise: string;
  revenue: string;
  video: string;
  menu: {
    breakfast: string;
    morning_snack: string;
    dinner: string;
    lunch: string;
    snack: string;
    supper: string;
  };
};

const Challenges: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { register, control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      title: "",
      description: "",
      challenge_of_week: "",
      exercise: "",
      revenue: "",
      video: "",
      menu: {
        breakfast: "",
        morning_snack: "",
        dinner: "",
        lunch: "",
        snack: "",
        supper: "",
      },
    },
  });
  const onSubmit = (data: FormData) => sendData(data);

  async function sendData({ id, ...data }: FormData) {
    console.log("sendData", data);

    const _data: Partial<FormData> = { ...data };
    if (id) _data.id = id;

    if (!id) await database.collection("topics").add(_data);
    else await database.collection("topics").doc(id).set(_data);

    history.push("/challenges");
  }

  useEffect(() => {
    if (!id) return;

    database
      .collection("topics")
      .doc(id)
      .get()
      .then((r) => {
        const item: FormData = { ...(r.data() as FormData), id: r.id };

        Object.keys(item).map((key) =>
          setValue(key as keyof FormData, item[key as keyof FormData])
        );
      });
  }, [id]);

  return (
    <React.Fragment>
      {!id && <Title>Novo Desafio</Title>}
      {!!id && <Title>Editar Desafio</Title>}

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={3}>
              <input {...register("id")} type="hidden" name="id" />
              <Grid item xs={12}>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Titulo"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Descrição"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="challenge_of_week"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Desafio da semana"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="menu.morning_snack"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Lanche da manhã"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="menu.breakfast"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Café da manhã"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="menu.lunch"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Almoço"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="menu.snack"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Café da tarde"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="menu.dinner"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Jantar"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="menu.supper"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Ceia"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="video"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Video"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="exercise"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Exercicio"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="revenue"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Receita"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default Challenges;

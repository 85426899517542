import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyAhLS04zUsF-2NQj3bD4p1LsRhyLdRKRjg",
  authDomain: "dias-para-recomecar.firebaseapp.com",
  projectId: "dias-para-recomecar",
  storageBucket: "dias-para-recomecar.appspot.com",
  messagingSenderId: "152937406432",
  appId: "1:152937406432:web:fd56824ec3f0baedfc5585",
  measurementId: "G-YH7Q4YRTZW",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const database = firebase.firestore();
export const auth = firebase.auth;

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import { PrivateMasterPage } from "../masterPages/private";
import Challenges from "../pages/Challenges";
import CreateChallenge from "../pages/Challenges/create";
import Stores from "../pages/Stores";
import CreateStore from "../pages/Stores/create";
import Users from "../pages/Users";
import CreateUser from "../pages/Users/create";

const PrivateRoutes: React.FC = () => {
  const { user } = useAuth();
  return (
    <PrivateMasterPage>
      <Switch>
        {!user?.id && (
          <Route path="/">
            <Redirect to="/signin" />
          </Route>
        )}
        <Route exact path="/challenges">
          <Challenges />
        </Route>
        <Route path={["/challenges/new/:id", "/challenges/new"]}>
          <CreateChallenge />
        </Route>
        <Route exact path="/stores">
          <Stores />
        </Route>
        <Route path={["/stores/new/:id", "/stores/new"]}>
          <CreateStore />
        </Route>
        <Route exact path="/users">
          <Users />
        </Route>
        <Route path={["/users/new/:id", "/users/new"]}>
          <CreateUser />
        </Route>
      </Switch>
    </PrivateMasterPage>
  );
};

export default PrivateRoutes;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Title } from "../../components/Title";
import { database } from "../../config/firebase";
import { Fab, IconButton, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Delete";
import { Link, useHistory } from "react-router-dom";

// import { Container } from './styles';

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const profiles: any = { "1": "Usuario", "2": "Admin" };

const Users: React.FC = () => {
  const history = useHistory();
  const [users, setUsers] = useState<any[]>([]);
  const classes = useStyles();

  function loadData() {
    database
      .collection("users")
      .get()
      .then((r) => setUsers(r.docs.map((d) => ({ id: d.id, ...d.data() }))));
  }

  useEffect(() => {
    loadData();
  }, []);

  async function removeItem(id: string) {
    if (window.confirm("Deseja realmente remover esse item?")) {
      await database.collection("users").doc(id).delete();
      loadData();
    }
  }

  return (
    <React.Fragment>
      <Title>Usuários</Title>

      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table id="challenges" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="10%">#</TableCell>
                  <TableCell width="20%">Nome</TableCell>
                  <TableCell width="20%">Tipo</TableCell>
                  <TableCell width="25%">E-mail</TableCell>
                  <TableCell width="10%">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell width="10px">
                      <Link to={`/users/new/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell width="20%">{row.name}</TableCell>
                    <TableCell width="20%">{profiles[row.type]}</TableCell>
                    <TableCell width="25%">{row.email}</TableCell>
                    <TableCell>
                      <IconButton
                        color="inherit"
                        onClick={() => removeItem(row?.id)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Fab
            className={classes.fab}
            color="primary"
            aria-label="add"
            onClick={() => history.push(`/users/new`)}
          >
            <AddIcon />
          </Fab>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default Users;

import React, { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import * as auth from "../services/auth";
import { IUser } from "../interfaces/IUser";

interface AuthContextData {
  signed: boolean;
  token: string;
  user: IUser | null;
  loading: boolean;
  signIn(data: any): Promise<void>;
  signOut(): Promise<void>;
  updateUser(data: IUser): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);

  async function signIn(data: any) {
    const user = await auth.signIn(data);

    localStorage.setItem("@project40days:user", JSON.stringify(user));
    setUser(user);
  }

  async function updateUser(data: IUser) {
    setUser(data);

    localStorage.setItem("@project40days:user", JSON.stringify(data));
  }

  async function signOut() {
    await localStorage.clear();
    setUser(null);
  }

  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = await localStorage.getItem("@project40days:user");

      console.log("useAuth effect", { storagedUser });

      firebase
        .auth()
        .signInAnonymously()
        .then((s) => {
          console.log("login anonimo realizado com sucesso", s);
        })
        .catch((e) => {
          console.log("erro no login anonimo", e);
        });

      if (storagedUser) {
        setUser(JSON.parse(storagedUser));
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        token: "",
        user,
        loading,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export default AuthContext;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Title } from "../../components/Title";
import { database } from "../../config/firebase";
import { Fab, IconButton, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Delete";
import { Link, useHistory } from "react-router-dom";

// import { Container } from './styles';

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const Challenges: React.FC = () => {
  const history = useHistory();
  const [topics, setTopics] = useState<any[]>([]);
  const classes = useStyles();

  function loadData() {
    database
      .collection("topics")
      .orderBy("title", "asc")
      .get()
      .then((r) => {
        const data = r.docs.map((d) => ({ id: d.id, ...d.data() }));
        setTopics(data);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  async function removeItem(id: string) {
    if (window.confirm("Deseja realmente remover esse item?")) {
      await database.collection("topics").doc(id).delete();
      loadData();
    }
  }

  return (
    <React.Fragment>
      <Title>Desafios</Title>

      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table id="challenges" size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10%" }}>#</TableCell>
                  <TableCell style={{ width: "15%" }}>Titulo</TableCell>
                  <TableCell style={{ width: "30%" }}>Descrição</TableCell>
                  <TableCell style={{ width: "15%" }}>Exercicio</TableCell>
                  <TableCell style={{ width: "15%" }}>Video</TableCell>
                  <TableCell style={{ width: "15%" }}>Receita</TableCell>
                  <TableCell width="10%">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topics.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      onClick={() => history.push(`/challenges/new/${row.id}`)}
                    >
                      <Link to={`/challenges/new/${row.id}`}>
                        <span style={{ wordBreak: "break-word" }}>
                          {row.id}
                        </span>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <span style={{ wordBreak: "break-word" }}>
                        {row.title}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ wordBreak: "break-word" }}>
                        {row.description}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ wordBreak: "break-word" }}>
                        {row.exercise}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ wordBreak: "break-word" }}>
                        {row.video}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ wordBreak: "break-word" }}>
                        {row.revenue}
                      </span>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="inherit"
                        onClick={() => removeItem(row?.id)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={() => history.push(`/challenges/new`)}
        >
          <AddIcon />
        </Fab>
      </div>
    </React.Fragment>
  );
};

export default Challenges;

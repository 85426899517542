import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../context/auth";
import SignIn from "../pages/SignIn";
import PrivateRoutes from "./private";

const Routes: React.FC = () => {
  const { user } = useAuth();
  return (
    <Router>
      <Switch>
        <Route path="/signin">
          {user?.id && <Redirect to="/" />}
          <SignIn />
        </Route>
        <Route path="/">
          <PrivateRoutes />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;

import { database } from "../config/firebase";
import bcrypt from "bcryptjs";

export async function signIn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const docUsers = await database
    .collection("users")
    .where("email", "==", email)
    .where("type", "==", 2)
    .get();
  const docUser = docUsers.docs?.[0];
  const user: any = { id: docUser?.id, ...docUser?.data() };

  console.log("signIn", { email, password, user });

  if (!user?.id) throw new Error("E-mail inválido");

  const valid = await bcrypt.compare(password, user?.password_hash);

  if (!valid) throw new Error("Senha inválida");
  // throw { message: "E-mail ou senha inválido", field: "password" };

  return user;
}

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import bcrypt from "bcryptjs";

import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";

import { Title } from "../../components/Title";
import { database } from "../../config/firebase";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";

// import { Container } from './styles';

import "./styles.css";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "2rem",
  },
}));

type FormData = {
  id: string;
  name: string;
  email: string;
  weight: string;
  target_weight: string;
  height: string;
  password: string;
  password_hash: string;
  type: string;
};

const CreateUser: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { register, control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      id: "",
      name: "",
      email: "",
      weight: "",
      target_weight: "",
      height: "",
      password: "",
      password_hash: "",
      type: "",
    },
  });
  const onSubmit = (data: FormData) => sendData(data);

  async function sendData({ id, password, ...data }: FormData) {
    console.log("sendData", data);
    const _data: Partial<FormData> = { ...data };
    if (id) _data.id = id;
    if (password) _data.password_hash = await bcrypt.hash(password, 10);

    if (!id) await database.collection("users").add(_data);
    else await database.collection("users").doc(id).set(_data);

    history.push("/users");
  }

  useEffect(() => {
    if (!id) return;

    database
      .collection("users")
      .doc(id)
      .get()
      .then((r) => {
        const item: FormData = { ...(r.data() as FormData), id: r.id };

        Object.keys(item).map((key) =>
          setValue(key as keyof FormData, item[key as keyof FormData])
        );
      });
  }, [id]);

  return (
    <React.Fragment>
      {!id && <Title>Nova Usuario</Title>}
      {!!id && <Title>Editar Usuario</Title>}

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={3}>
              <input {...register("id")} type="hidden" name="id" />
              <input
                {...register("password_hash")}
                type="hidden"
                name="password_hash"
              />

              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Nome"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="E-mail"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="type">Tipo</InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        labelId="type"
                        id="type "
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                      >
                        <MenuItem value="">
                          <em>Selecione</em>
                        </MenuItem>
                        <MenuItem value={1}>Usuario</MenuItem>
                        <MenuItem value={2}>Admin</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="weight"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Peso"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="target_weight"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Meta de Peso"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="height"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Altura"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={field.name}
                      label="Senha"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default CreateUser;

import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInValidationSchema } from "../../validationSchemas/signin";
import { useAuth } from "../../context/auth";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        40Dias
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type FormData = {
  email: string;
  password: string;
};

export default function SignIn() {
  const [msgError, setMsgError] = useState("");
  const { signIn } = useAuth();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(signInValidationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const classes = useStyles();

  const onSubmit = (data: FormData) => sendData(data);

  async function sendData(data: FormData) {
    try {
      console.log("sendData", data);
      await signIn(data);
    } catch (error) {
      console.log("sendData signin error", error);
      setMsgError(error.message);
    }
  }

  console.log("render signin", { errors });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, console.log)}
        >
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.email}
          >
            <InputLabel htmlFor="component-error">E-mail</InputLabel>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id={field.name}
                  name={field.name}
                  label="E-mail"
                  required
                  fullWidth
                  autoComplete="email"
                  autoFocus
                />
              )}
            />
            {!!errors.email && (
              <FormHelperText id="component-error-text">
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.password}
          >
            <InputLabel htmlFor="component-error">Senha</InputLabel>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id={field.name}
                  name={field.name}
                  required
                  fullWidth
                  label="Senha"
                  type="password"
                  autoComplete="off"
                />
              )}
            />
            {!!errors.password && (
              <FormHelperText id="component-error-text">
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>
          {!!msgError && (
            <Alert variant="filled" severity="error">
              {msgError}
            </Alert>
          )}

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
